/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable new-cap */
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import View from 'views/View'
import { faSearch, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Collapse, Grid, Slide, IconButton } from '@mui/material'
import {
    AdvancedFilters,
    UploadRepresentmentsRouter,
    AccentArea,
    MerchantSwitcher,
    CaseFilter,
    FilterAlert,
} from 'components'
import idDirectory from './idAttributes'
import { withRequiredRole } from 'components/useRequireRole'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import EntFeature from 'models/EntFeature'
import { useChargebacks } from './Chargebacks.vm'
import { BulkCloseDialog } from './dialogs/BulkCloseDialog'
import { ChargebacksGrid } from './grids/ChargebacksGrid'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { SWRContextProvider as ChargebacksContextProvider } from 'context/SWRContext/SWRContext'
import { AdvancedSearchValues } from 'hooks/cb-api/types'
import { clientTheme } from 'theme-exports'
import AlertSnackbar from 'components/AlertSnackbar'
import { EditChargeback } from './modals/EditChargeback'
import spaceshipIcon from 'assets/icons/icon-spaceship.svg'
import { CBNewRelic } from 'lib'

/**
 * The Chargebacks view with a datagrid
 */
const Chargebacks = ({ ...props }: any) => {
    const history = useHistory()
    const {
        activeMerchantId,
        cases,
        advanceSearchFields,
        advancedSearchValues,
        handleAdvancedSearchSubmit,
        handleAdvancedSearchClear,
        contextMenuItems,
        isUploadOpen,
        closeUpload,
        selectedCaseIds,
        clearSelectedRows,
        handleCaseFilterChange,
        rehydrateView,
        canViewPartnerCompanyId,
        canViewIssuerDocs,
        canViewOutcomeVerdicts,
        canViewPlatform,
        disableSelectAll,
        swrActions,
        metricPresets,
        setMetricPresets,
        collapseOpen,
        setCollapseOpen,
        params,
        isLoadingAdvFilterSelect,
        setIsLoadingAdvFilterSelect,
        openEditChargebacksModal,
        setOpenEditChargebacksModal,
        isLoadingAdvFilterFields,
        alertSnackbarMainOpen,
        setAlertSnackbarMainOpen,
        alertSnackbarMainProps,
        setAlertSnackbarMainProps,
    } = useChargebacks(props)

    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const [showCaseFiltersDrawer, setCaseFiltersDrawer] = useState(false)
    const [bulkCloseOpen, setBulkCloseOpen] = useState(false)
    const { CASE_FILTERS, AUTO_ACCEPT, HELP_LINK } = useFeatureToggle(
        'CHARGEBACKS'
    )
    const { enabled: showCaseFilterButton } = CASE_FILTERS
    const {
        enabled: helpLinkEnabled,
        text: helpLinkText,
        url: helpLinkURL,
    } = HELP_LINK
    const [alertFilterOpen, setAlertFilterOpen] = useState<boolean>(false)
    const [openAlertMsgBanner, setOpenAlertMsgBanner] = useState<boolean>(false)
    const { unselectAllRows } = swrActions

    const handleFilterAlert = (filters: AdvancedSearchValues) => {
        Object.values(filters).some((item) => item.length && item !== 'eq')
            ? setAlertFilterOpen(true)
            : setAlertFilterOpen(false)
    }

    const handleOnCloseAlertBanner = async () => {
        setOpenAlertMsgBanner(false)
        try {
            await axios.post(`/users/pro-service/banner/close`, {
                page: 'cb',
            })
        } catch (error) {}
    }

    const handleLearnMore = async () => {
        history.push('/case-management')
        setOpenAlertMsgBanner(false)
        try {
            await axios.post(`/users/pro-service/banner/close`, {
                page: 'cb',
            })
            await CBNewRelic.sendNewRelicEvent({
                key: 'pro-service',
                case_id: null,
                event_name: 'Case Management learn more button clicked on chargebacks page',
                event_action: 'clicked',
                event_message: 'Case Management learn more button clicked on chargebacks page',
            })
        } catch (error) {}
    }

    useEffect(() => {
        axios
            .get('/users/pro-service/banner', {
                params: {
                    page: 'cb',
                },
            })
            .then(({ data }) => {
                setOpenAlertMsgBanner(Boolean(data?.show))
            })
    }, [])

    return (
        <ChargebacksContextProvider SWRActions={swrActions}>
            <View
                title="Chargebacks"
                breadcrumb="Chargebacks"
                HeaderInterior={<MerchantSwitcher testId="chargebacks" />}
                MiddleInterior={
                    openAlertMsgBanner ? (
                        <div className={'emp-alertBannerContainer'}>
                            <div className={'emp-alertBannerText'}>
                                <img src={spaceshipIcon} alt="spaceship-icon" />
                                <div>
                                    <span
                                        style={{
                                            fontWeight: 600,
                                            color: '#ff0000',
                                        }}
                                    >
                                        Case Manager is now live on your
                                        account!
                                    </span>{' '}
                                    This new tool includes all of the features
                                    you are currently using, with a fresh
                                    interface and improved team collaboration.
                                </div>
                            </div>
                            <div className={'emp-alertBannerActions'}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size={'small'}
                                    className={'emp-alertBannerBtn'}
                                    onClick={handleLearnMore}
                                >
                                    Learn More
                                </Button>
                                <IconButton
                                    size="small"
                                    onClick={handleOnCloseAlertBanner}
                                    className={'emp-alertBannerClose'}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </IconButton>
                            </div>
                        </div>
                    ) : null
                }
                testId="chargebacks"
            >
                <Grid
                    id={idDirectory.gridContainer}
                    container
                    spacing={1}
                    className={'emp-gridWrapperDefault'}
                >
                    <Grid
                        id={`${idDirectory.gridItem}-chargebacks`}
                        item
                        xs={12}
                        lg={
                            showCaseFiltersDrawer
                                ? 9
                                : collapseOpen
                                ? 12
                                : undefined
                        }
                    >
                        <AccentArea
                            testId={'chargebacks-cbHeader'}
                            className={'emp-accentArea'}
                            containsFullWidthGrid={true}
                            headerAction={
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div className={'emp-topContainer'}>
                                        <div>
                                            <Button
                                                id={idDirectory.btnAdvSearch}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    setCollapseOpen(
                                                        !collapseOpen
                                                    )
                                                }
                                                className={`emp-advSearchBtn`}
                                                style={{ marginRight: 4 }}
                                                disabled={
                                                    isLoadingAdvFilterFields ||
                                                    !advanceSearchFields.length
                                                }
                                            >
                                                <span
                                                    className={
                                                        'emp-advSearchIcon'
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faSearch}
                                                    />
                                                </span>
                                                &nbsp; Advanced Search
                                            </Button>
                                            {showCaseFilterButton && (
                                                <Button
                                                    id={
                                                        idDirectory.btnCaseFilter
                                                    }
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>
                                                        setCaseFiltersDrawer(
                                                            !showCaseFiltersDrawer
                                                        )
                                                    }
                                                    className={`emp-advSearchBtn`}
                                                    sx={[
                                                        showCaseFiltersDrawer && {
                                                            backgroundColor:
                                                                clientTheme.secondaryDark,
                                                        },
                                                    ]}
                                                >
                                                    Case Filters
                                                </Button>
                                            )}
                                            {!AUTO_ACCEPT.enabled ? null : (
                                                <Button
                                                    id={
                                                        idDirectory.btnAutoAccept
                                                    }
                                                    variant="contained"
                                                    onClick={() =>
                                                        setBulkCloseOpen(true)
                                                    }
                                                    className={
                                                        'emp-autoAcceptBtn'
                                                    }
                                                    disabled={isReadOnly}
                                                    sx={
                                                        clientTheme.buttons
                                                            .greyButton.style
                                                    }
                                                >
                                                    Auto Accept
                                                </Button>
                                            )}
                                        </div>
                                        {!helpLinkEnabled ? null : (
                                            <a
                                                className={'emp-helpLink'}
                                                href={helpLinkURL}
                                                rel="external noopener noreferrer"
                                                target="_blank"
                                            >
                                                {helpLinkText}
                                            </a>
                                        )}
                                        <div
                                            className={
                                                'emp-filterAlertContainer'
                                            }
                                        >
                                            {alertFilterOpen && <FilterAlert />}
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <Collapse in={collapseOpen} timeout="auto">
                                <div
                                    className={'emp-advancedFilterContent'}
                                    style={{
                                        background:
                                            clientTheme.mainLayout
                                                .backgroundColor,
                                    }}
                                >
                                    {Boolean(advanceSearchFields.length) && (
                                        <AdvancedFilters
                                            fields={advanceSearchFields}
                                            metricPresets={metricPresets}
                                            validate={() => ({})}
                                            onSubmit={(filters) => {
                                                handleFilterAlert(filters)
                                                handleAdvancedSearchSubmit(
                                                    filters,
                                                    true
                                                )
                                            }}
                                            onClear={() => {
                                                setAlertFilterOpen(false)
                                                handleAdvancedSearchClear()
                                            }}
                                            initialValues={
                                                advancedSearchValues as Record<
                                                    string,
                                                    string
                                                >
                                            }
                                            isActiveAdvSearchFilters={
                                                alertFilterOpen
                                            }
                                            setIsLoadingAdvFilterSelect={
                                                setIsLoadingAdvFilterSelect
                                            }
                                            inactivateSearchBtn={
                                                swrActions.isLoading
                                            }
                                        />
                                    )}
                                </div>
                            </Collapse>
                            {/* isMultiSelect is always true as this upload modal opens from the context menu, not the manual upload icon */}
                            <UploadRepresentmentsRouter
                                cases={cases}
                                caseIds={selectedCaseIds}
                                openModal={isUploadOpen}
                                toggleModal={closeUpload}
                                rehydrateView={rehydrateView}
                                isMultiSelect
                                setAlertSnackbarMainProps={
                                    setAlertSnackbarMainProps
                                }
                                setAlertSnackbarMainOpen={
                                    setAlertSnackbarMainOpen
                                }
                                unselectAllRows={unselectAllRows}
                            />
                            <ChargebacksGrid
                                data={cases}
                                contextMenuItems={contextMenuItems}
                                canViewPartnerCompanyId={
                                    canViewPartnerCompanyId
                                }
                                canViewIssuerDocs={canViewIssuerDocs}
                                canViewOutcomeVerdicts={canViewOutcomeVerdicts}
                                canViewPlatform={canViewPlatform}
                                disableSelectAllCheckbox={disableSelectAll}
                                params={params}
                                clearSelectedRows={clearSelectedRows}
                                setAlertSnackbarMainProps={
                                    setAlertSnackbarMainProps
                                }
                                setAlertSnackbarMainOpen={
                                    setAlertSnackbarMainOpen
                                }
                                isLoadingAdvFilters={
                                    isLoadingAdvFilterSelect ||
                                    isLoadingAdvFilterFields
                                }
                            />
                        </AccentArea>
                    </Grid>
                    <Slide
                        direction="left"
                        in={showCaseFiltersDrawer}
                        mountOnEnter
                        unmountOnExit
                        style={{
                            position: 'sticky',
                            top: '60px',
                            zIndex: 999,
                        }}
                    >
                        <Grid
                            id={`${idDirectory.gridItem}-caseFilter`}
                            item
                            xs={12}
                            lg={3}
                        >
                            <AccentArea
                                testId={'chargebacks-caseFilter'}
                                title={
                                    <>
                                        <FontAwesomeIcon
                                            icon={faFilter}
                                            className={'emp-marginRight'}
                                        />
                                        <span>Case Filters</span>
                                    </>
                                }
                                className={'emp-caseFilterArea'}
                            >
                                <CaseFilter
                                    title="Queue"
                                    clientId={activeMerchantId}
                                    onFilterChange={handleCaseFilterChange}
                                    onClose={() => setCaseFiltersDrawer(false)}
                                    clearAdvanceSearch={() => {
                                        setAlertFilterOpen(false)
                                        handleAdvancedSearchClear()
                                    }}
                                    isActiveAdvSearchFilters={alertFilterOpen}
                                    params={params}
                                    setMetricPresets={setMetricPresets}
                                    setAlertFilterOpen={setAlertFilterOpen}
                                    setAlertSnackbarMainProps={
                                        setAlertSnackbarMainProps
                                    }
                                    setAlertSnackbarMainOpen={
                                        setAlertSnackbarMainOpen
                                    }
                                />
                            </AccentArea>
                        </Grid>
                    </Slide>
                </Grid>
                <BulkCloseDialog
                    open={bulkCloseOpen}
                    onClose={() => setBulkCloseOpen(false)}
                />
                {openEditChargebacksModal && (
                    <EditChargeback
                        openModal={openEditChargebacksModal}
                        onClose={() => setOpenEditChargebacksModal(false)}
                        caseIds={selectedCaseIds}
                        rehydrateView={rehydrateView}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                        enableBulkAction
                        unselectAllRows={unselectAllRows}
                    />
                )}
                <AlertSnackbar
                    content={alertSnackbarMainProps}
                    open={alertSnackbarMainOpen}
                    onClose={() => setAlertSnackbarMainOpen(false)}
                    showCloseIcon
                />
            </View>
        </ChargebacksContextProvider>
    )
}

export default withRequiredRole(EntFeature.CHARGEBACKS, Chargebacks)
