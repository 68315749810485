import React from 'react'
import { Menu, MenuItem } from '@mui/material'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { clientTheme } from 'theme-exports'
import { ContextMenuOption, ISelectedRows } from 'hooks/cb-api'

interface ContextMenuProps {
    className?: string
    id?: string
    menuItems: ContextMenuOption[]
    mousePosition: ContextMenuState
    handleClose: (operator?: any) => void // closes the context menu and if passed an operator, that operator will be used as the menu closes. ie. if you click an upload action, it will fire as the menu closes
    noRowSelections: boolean
    contextMenuRowData: ISelectedRows
}

interface ContextMenuState {
    mouseX: null | number
    mouseY: null | number
}

/**
 * Use ContextMenu to render a context menu at the mouse pointer position at the time of a right click
 * @param menuItems[] - the menu items array consisits of objects that are used to create menu options within the ContextMenu.
 * @param menuItem { value: string, operator: () => void }
 * @param value is used for display purposes to render the menu item
 * @param operator function originates in the parent, and is used for a menu item's onclick action
 * @param mousePosition - the coordinates (x,y) position of the mouse cursor at time of activating the context menu
 * @param handleClose run when the context menu is closed (the datgrid resets the mouse position, and fires the operator if there is one)
 */
const ContextMenu = ({
    className = '',
    id = '',
    menuItems,
    mousePosition,
    handleClose,
    noRowSelections,
    contextMenuRowData,
}: ContextMenuProps): React.ReactElement => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    const checkMenuItemDisabledStatus = (
        isDisabled:
            | boolean
            | ((selectedRows: ISelectedRows) => boolean)
            | undefined
    ) => {
        if (typeof isDisabled === 'function') {
            return isDisabled(contextMenuRowData)
        } else {
            return isDisabled
        }
    }

    return (
        <>
            <Menu
                id={id}
                classes={{ list: 'emp-contextMenu-root' }}
                keepMounted
                variant="menu"
                disableAutoFocusItem
                open={mousePosition.mouseY !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    mousePosition.mouseY !== null &&
                    mousePosition.mouseX !== null
                        ? {
                              top: mousePosition.mouseY,
                              left: mousePosition.mouseX,
                          }
                        : undefined
                }
            >
                {menuItems
                    .filter((item: any) => !item.isHidden)
                    .map((item, i) => (
                        <MenuItem
                            className={`${className} ${'emp-contextMenu-item'}`}
                            onClick={() => handleClose(item.operator)}
                            key={i}
                            disabled={
                                noRowSelections ||
                                isReadOnly ||
                                checkMenuItemDisabledStatus(item.isDisabled)
                            }
                            sx={{
                                '& > span svg': {
                                    color: clientTheme.secondary,
                                },

                                '&:hover': {
                                    background: clientTheme.secondaryLight,
                                    color: '#fff',

                                    '& > span svg': {
                                        color: '#fff',
                                    },
                                },
                            }}
                        >
                            <span>
                                {item.icon ? (
                                    <Icon
                                        className={'emp-contextMenu-itemIcon'}
                                        icon={item.icon}
                                    />
                                ) : null}
                            </span>
                            {item.value}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    )
}

export default ContextMenu
