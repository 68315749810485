/**
 * @description Directory for ID Attributes on MessageModal.
 * Naming Convention: "[VIEW/COMPONENT]-[ELEMENT]-[NAME]".
 * Example: id = "mainlayout-btn-cancel".
 */
const idDirectory = {
    divRoot: `messageModal-div-root`,
    divTitle: `messageModal-div-title`,
    divContent: `messageModal-div-content`,
    divActions: `messageModal-div-actions`,
    btnAccept: `messageModal-btn-accept`,
    btnClose: `messageModal-btn-close`,
    btnDeny: `messageModal-btn-deny`,
}

export default idDirectory
