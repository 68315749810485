import React from 'react'
import axios from 'axios'
import { Button, Popover, Typography } from '@mui/material'
import ViewIconActive from 'assets/icons/view-icon-active.svg'
import { iconsTheme } from 'theme-exports'
import Icon from 'components/Icon'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface IIssuerDocsProps {
    anchorEl: null | HTMLElement
    onClose: () => void
    issuerDocs: {
        date_created: string
        filename: string
        id: number
    }[]
    caseId: number
    rowIndex: number
    testId: string
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
}

export const IssuerDocs = ({
    anchorEl,
    onClose,
    issuerDocs,
    caseId,
    rowIndex,
    testId,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
}: IIssuerDocsProps) => {
    const handleDownloadIssuerDoc = async (issuerDocId: number) => {
        try {
            const response = await axios.get(
                `/docs/issuer-doc/${caseId}/${issuerDocId}`
            )
            const { data } = response

            let a = document.createElement('a')
            a.href = data.uri
            a.setAttribute('download', data.filename)
            document.body.appendChild(a)
            a.click()
            a.remove()
        } catch (error) {
            setAlertSnackbarMainProps({
                title: 'Error',
                message: `An error occurred. Please try again later.`,
                intent: 'error',
            })
            setAlertSnackbarMainOpen(true)
        }
    }

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            id={'chargebacksAGIssuerDocs'}
        >
            <div
                id={`${testId}-issuer-docs-${rowIndex}`}
                className={'emp-issuerDocsContainer'}
            >
                {issuerDocs.map(
                    (doc: {
                        date_created: string
                        filename: string
                        id: number
                    }) => {
                        return (
                            <Button
                                className={'emp-issuerDocBtn'}
                                onClick={() => handleDownloadIssuerDoc(doc.id)}
                                key={`key-doc-${doc.id}`}
                            >
                                <div>
                                    {iconsTheme.document ? (
                                        <Icon
                                            className={`${iconsTheme.document}`}
                                        />
                                    ) : (
                                        <img
                                            className={`emp-issuerDocIcon`}
                                            src={ViewIconActive}
                                            alt="view issuer doc"
                                        />
                                    )}
                                    <Typography>{doc.date_created}</Typography>
                                </div>
                            </Button>
                        )
                    }
                )}
            </div>
        </Popover>
    )
}
