import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory, useLocation } from 'react-router-dom'
import { MessageModal } from 'components'
import { useLocalStorage } from 'hooks/useLocalStorage/useLocalStorage'

interface IMessage {
    id: number
    header: string
    sub_header: string
    message: string
    show_on: string[]
    accept: {
        label: string
        redirect: string | null
    } | null
    deny: {
        label: string
        redirect: string | null
    } | null
    close: {
        label: string
        redirect: string | null
    } | null
}

// interface IMainLayoutModalProps {}

const MainLayoutModal = () => {
    const history = useHistory()
    const { pathname } = useLocation()

    const [messages, setMessages] = useState<IMessage[]>([])

    // Adding to localStorage will close modal and prevent modal from re-opening on refresh.
    const [disabledMessageIds, setDisabledMessageIds] = useLocalStorage<
        number[]
    >('disabledMessageIds', [])

    useEffect(() => {
        axios.get('/users/messages').then(({ data }) => {
            const updatedMessages = data.data ?? []
            setMessages(updatedMessages.reverse())
        })
    }, [])

    const handleOnClose = async (modalMsg: IMessage) => {
        try {
            setDisabledMessageIds([...disabledMessageIds, modalMsg.id])
            if (modalMsg.close?.redirect) {
                history.push(modalMsg.close?.redirect)
            }
            await axios.post(`/users/messages/${modalMsg.id}/action`, {
                action: 'close',
            })
        } catch (error) {}
    }

    const handleOnDeny = async (modalMsg: IMessage) => {
        try {
            setDisabledMessageIds([...disabledMessageIds, modalMsg.id])
            if (modalMsg.deny?.redirect) {
                history.push(modalMsg.deny?.redirect)
            }
            await axios.post(`/users/messages/${modalMsg.id}/action`, {
                action: 'deny',
            })
        } catch (error) {}
    }

    const handleOnAccept = async (modalMsg: IMessage) => {
        try {
            setDisabledMessageIds([...disabledMessageIds, modalMsg.id])
            if (modalMsg.accept?.redirect) {
                history.push(modalMsg.accept?.redirect)
            }
            await axios.post(`/users/messages/${modalMsg.id}/action`, {
                action: 'accept',
            })
        } catch (error) {}
    }

    const msgIdsWithPathname = messages
        .filter(
            (msg: IMessage) =>
                msg.show_on.includes(pathname) || !msg.show_on.length
        )
        .map((msg: IMessage) => msg.id)

    return messages.length ? (
        <>
            {messages.map((msg: IMessage, idx: number) => {
                // Needed to prevent modal from showing underneath the current open modal, especially if it's height is larger.
                const noMsgModalsOpenOnTop = !messages
                    .slice(idx + 1)
                    .map((previousMsg: IMessage) => previousMsg.id)
                    .some(
                        (previousMsgId: number) =>
                            msgIdsWithPathname.includes(previousMsgId) &&
                            !disabledMessageIds.includes(previousMsgId)
                    )

                return (
                    <MessageModal
                        key={`key-${msg.id}`}
                        openModal={
                            (msg.show_on.includes(pathname) ||
                                !msg.show_on.length) &&
                            !disabledMessageIds.includes(msg.id) &&
                            noMsgModalsOpenOnTop
                        }
                        onModalClose={() => handleOnClose(msg)}
                        testId={`mainModal-${msg.id}`}
                        modalHeader={msg.header}
                        modalSubHeader={msg.sub_header}
                        modalMessage={msg.message}
                        acceptBtnText={msg.accept?.label}
                        showAcceptActionBtn={Boolean(msg.accept?.label)}
                        handleAcceptActionBtn={() => handleOnAccept(msg)}
                        denyBtnText={msg.deny?.label}
                        showDenyActionBtn={Boolean(msg.deny?.label)}
                        handleDenyActionBtn={() => handleOnDeny(msg)}
                        closeBtnText={msg.close?.label}
                        showCloseActionBtn={Boolean(msg.close?.label)}
                        handleCloseActionBtn={() => handleOnClose(msg)}
                    />
                )
            })}
        </>
    ) : null
}

export default MainLayoutModal
