import React, { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Typography, TextField } from '@mui/material'
import useIsMounted from 'hooks/utils/useIsMounted'
import { clientTheme } from 'theme-exports'
import { SearchableSelect, StandardModal, LoadingIndicator } from 'components'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

type TOption = {
    id: number
    name?: string
}

type TEditChargebackInputs = {
    processor_invoice_id: string
    verdict_id: string | number
    case_amt: string
    reason_code_id: string | number
    currency_id: string | number | undefined
}

interface IEditChargebackProps {
    openModal: boolean
    onClose: () => void
    chargebackData?: { [key: string]: any }
    caseIds?: number[]
    refreshGrid: () => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    editVerdictOnly?: boolean
    enableBulkAction?: boolean
}

export const EditChargeback = ({
    openModal,
    onClose,
    chargebackData,
    caseIds,
    refreshGrid,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    editVerdictOnly = false,
    enableBulkAction = false,
}: IEditChargebackProps) => {
    const { isMounted } = useIsMounted()

    const [isLoadingVerdict, setIsLoadingVerdict] = useState(false)
    const [isLoadingReasonCode, setIsLoadingReasonCode] = useState(false)
    const [isLoadingCurrency, setIsLoadingCurrency] = useState(false)
    const [verdictOptions, setVerdictOptions] = useState<TOption[]>([])
    const [reasonCodeOptions, setReasonCodeOptions] = useState<TOption[]>([])
    const [currencyOptions, setCurrencyOptions] = useState<TOption[]>([])
    const [reasonCodeSearchValue, setReasonCodeSearchValue] = useState('')
    const [currencySearchValue, setCurrencySearchValue] = useState('')
    const [selectValues, setSelectValues] = useState({
        verdict_id: chargebackData?.verdict,
        reason_code_id: chargebackData?.reason_code,
        currency_id: chargebackData?.dispute_currency,
    })
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    const enableVerdictFieldOnly = editVerdictOnly || enableBulkAction

    useEffect(() => {
        if (openModal) {
            setIsLoadingVerdict(true)
            axios
                .get('cm/gen/verdicts', {
                    params: {
                        limit: 999,
                    },
                })
                .then((res) => {
                    const formattedVerdictOptions = res.data.data.map(
                        (item: { [key: string]: any }) => {
                            return { id: item.id, name: item.name }
                        }
                    )
                    if (isMounted) setVerdictOptions(formattedVerdictOptions)
                })
                .finally(() => setIsLoadingVerdict(false))
        }
        // eslint-disable-next-line
    }, [openModal])

    useEffect(() => {
        if (openModal) {
            setIsLoadingReasonCode(true)
            axios
                .get('cm/gen/rc', {
                    params: {
                        limit: 999,
                        search: reasonCodeSearchValue,
                    },
                })
                .then((res) => {
                    const formattedReasonCodeOptions = res.data.data.map(
                        (item: { [key: string]: any }) => {
                            return { id: item.id, name: item.code }
                        }
                    )
                    if (isMounted)
                        setReasonCodeOptions(formattedReasonCodeOptions)
                })
                .finally(() => setIsLoadingReasonCode(false))
        }
        // eslint-disable-next-line
    }, [openModal, reasonCodeSearchValue])

    useEffect(() => {
        if (openModal) {
            setIsLoadingCurrency(true)
            axios
                .get('/cm/gen/currencies', {
                    params: {
                        limit: 999,
                        search: currencySearchValue,
                    },
                })
                .then((res) => {
                    const formattedCurrencyOptions = res.data.data.map(
                        (item: { [key: string]: any }) => {
                            return { id: item.id, name: item.currency }
                        }
                    )
                    if (isMounted) setCurrencyOptions(formattedCurrencyOptions)
                })
                .finally(() => setIsLoadingCurrency(false))
        }
        // eslint-disable-next-line
    }, [openModal, currencySearchValue])

    const validationSchemaVerdictOnly = Yup.object().shape({
        verdict_id: Yup.number().min(1).required(),
    })

    const validationSchema = Yup.object().shape({
        processor_invoice_id: chargebackData?.processor_invoice_id
            ? Yup.string().required()
            : Yup.string().notRequired(),
        verdict_id: Yup.number().min(1).required(),
        ...(['new', 'not represented'].includes(
            chargebackData?.status_group?.toLowerCase()
        ) && {
            case_amt: chargebackData?.dispute_amt
                ? Yup.string().required()
                : Yup.string().notRequired(),
            reason_code_id: Yup.number().min(1).required(),
            currency_id: Yup.mixed().required(),
        }),
    })

    const defaultValues = useMemo<TEditChargebackInputs>(() => {
        return {
            processor_invoice_id: chargebackData?.processor_invoice_id ?? '',
            verdict_id: chargebackData?.verdict_id ?? 0,
            case_amt: chargebackData?.dispute_amt ?? '',
            reason_code_id: chargebackData?.reason_code_id ?? 0,
            currency_id: chargebackData?.dispute_currency ?? '',
        }
    }, [chargebackData])

    const {
        reset,
        register,
        handleSubmit,
        setValue,
        formState: { isSubmitting, isDirty, isValid },
        // TODO: Known React-Hook-Form typescript issue - Should be resolved with RHF's v8. Once resolved, need to switch typescript's "any" to "TEditChargebackInputs".
    } = useForm<any>({
        defaultValues: defaultValues,
        values: defaultValues,
        mode: 'onChange',
        resolver: enableVerdictFieldOnly
            ? yupResolver(validationSchemaVerdictOnly)
            : yupResolver(validationSchema),
    })

    const onSubmit: SubmitHandler<TEditChargebackInputs> = async (values) => {
        try {
            if (enableBulkAction) {
                const editRows = caseIds?.map((id: number) => ({
                    id: id,
                    verdict_id: values.verdict_id,
                }))

                await axios.put(`/cm/cases/edit`, { cases: editRows })
            } else {
                await axios.patch(
                    `/cm/cases/${chargebackData?.id}/edit`,
                    editVerdictOnly
                        ? {
                              verdict_id: values.verdict_id,
                          }
                        : {
                              ...values,
                          }
                )
            }
            setAlertSnackbarMainProps({
                title: 'Success',
                intent: 'success',
                message: 'Chargeback(s) successfully updated.',
            })
            refreshGrid()
            onClose()
            return setAlertSnackbarMainOpen(true)
        } catch (error) {
            setAlertSnackbarProps({
                title: 'Error',
                intent: 'error',
                message: 'An error occurred while updating chargeback(s).',
            })
            return setAlertSnackbarOpen(true)
        }
    }

    const handleSelectedValue = (
        key: 'verdict_id' | 'reason_code_id' | 'currency_id',
        e: TOption | null
    ) => {
        setValue(key, e?.id, { shouldDirty: true, shouldValidate: true })
        setSelectValues({
            ...selectValues,
            [key]: e?.name,
        })
    }

    const handleOnClose = () => {
        onClose()
        reset()
    }

    return (
        <StandardModal
            openModal={openModal}
            onModalClose={handleOnClose}
            modalHeaderTitle={'Edit Chargeback'}
            testId={'editChargeback'}
            maxModalWidth={enableVerdictFieldOnly ? 'xs' : 'md'}
            primaryBtnText={'Save'}
            handlePrimaryActionBtn={handleSubmit(onSubmit)}
            inactivatePrimaryActionBtn={isSubmitting || !isDirty || !isValid}
            isLoadingPrimaryActionBtn={isSubmitting}
            handleSecondaryActionBtn={handleOnClose}
            className={`${
                !enableVerdictFieldOnly &&
                'emp-chargebacks-editChargeback-modalContainer'
            }`}
        >
            <div id={'chargebacksEditChargeback'}>
                {!enableBulkAction &&
                Object.keys(chargebackData ?? {}).length === 0 ? (
                    <div className="emp-loadingContainer">
                        <LoadingIndicator />
                    </div>
                ) : (
                    <form>
                        {enableVerdictFieldOnly ? (
                            <div className={'emp-mainContainer'}>
                                <div className="emp-mainContainerColumn">
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Outcome:
                                        </Typography>
                                        <div className={'emp-fieldContainer'}>
                                            <SearchableSelect
                                                value={
                                                    selectValues.verdict_id ??
                                                    'Please Select'
                                                }
                                                onValueChange={(e) =>
                                                    handleSelectedValue(
                                                        'verdict_id',
                                                        e
                                                    )
                                                }
                                                options={
                                                    !isLoadingVerdict
                                                        ? verdictOptions ?? []
                                                        : []
                                                }
                                                accessor={'name'}
                                                testId={'outcome'}
                                                debounceDelay={200}
                                                hideSearch
                                                isPaginate={false}
                                                loadingValues={isLoadingVerdict}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ['new', 'not represented'].includes(
                              chargebackData?.status_group?.toLowerCase()
                          ) ? (
                            <div className={'emp-mainContainer'}>
                                <div className="emp-mainContainerColumn">
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Processor Invoice ID:
                                        </Typography>
                                        <TextField
                                            {...register(
                                                'processor_invoice_id'
                                            )}
                                            name={'processor_invoice_id'}
                                            fullWidth
                                            variant={'outlined'}
                                            size="small"
                                            className={'emp-fieldContainer'}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius:
                                                        clientTheme.selectionBox
                                                            .borderRadius,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Chargeback Amount:
                                        </Typography>
                                        <TextField
                                            {...register('case_amt')}
                                            name={'case_amt'}
                                            fullWidth
                                            variant={'outlined'}
                                            size="small"
                                            className={'emp-fieldContainer'}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius:
                                                        clientTheme.selectionBox
                                                            .borderRadius,
                                                },
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Outcome:
                                        </Typography>
                                        <div className={'emp-fieldContainer'}>
                                            <SearchableSelect
                                                value={selectValues.verdict_id}
                                                onValueChange={(e) =>
                                                    handleSelectedValue(
                                                        'verdict_id',
                                                        e
                                                    )
                                                }
                                                options={
                                                    !isLoadingVerdict
                                                        ? verdictOptions ?? []
                                                        : []
                                                }
                                                accessor={'name'}
                                                testId={'outcome'}
                                                debounceDelay={200}
                                                hideSearch
                                                isPaginate={false}
                                                loadingValues={isLoadingVerdict}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="emp-mainContainerColumn">
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Reason Code:
                                        </Typography>
                                        <div className={'emp-fieldContainer'}>
                                            <SearchableSelect
                                                value={
                                                    selectValues.reason_code_id
                                                }
                                                onValueChange={(e) =>
                                                    handleSelectedValue(
                                                        'reason_code_id',
                                                        e
                                                    )
                                                }
                                                searchValue={
                                                    reasonCodeSearchValue
                                                }
                                                onSearchChange={
                                                    setReasonCodeSearchValue
                                                }
                                                options={
                                                    !isLoadingReasonCode
                                                        ? reasonCodeOptions ??
                                                          []
                                                        : []
                                                }
                                                accessor={'name'}
                                                testId={'reasonCode'}
                                                debounceDelay={200}
                                                loadingValues={
                                                    isLoadingReasonCode
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Chargeback Currency:
                                        </Typography>
                                        <div className={'emp-fieldContainer'}>
                                            <SearchableSelect
                                                value={selectValues.currency_id}
                                                onValueChange={(e) =>
                                                    handleSelectedValue(
                                                        'currency_id',
                                                        e
                                                    )
                                                }
                                                searchValue={
                                                    currencySearchValue
                                                }
                                                onSearchChange={
                                                    setCurrencySearchValue
                                                }
                                                options={
                                                    !isLoadingCurrency
                                                        ? currencyOptions ?? []
                                                        : []
                                                }
                                                accessor={'name'}
                                                testId={'chargebackCurrency'}
                                                debounceDelay={200}
                                                loadingValues={
                                                    isLoadingCurrency
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={'emp-mainContainer'}>
                                <div className="emp-mainContainerColumn">
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Processor Invoice ID:
                                        </Typography>
                                        <TextField
                                            {...register(
                                                'processor_invoice_id'
                                            )}
                                            name={'processor_invoice_id'}
                                            fullWidth
                                            variant={'outlined'}
                                            size="small"
                                            className={'emp-fieldContainer'}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    borderRadius:
                                                        clientTheme.selectionBox
                                                            .borderRadius,
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="emp-mainContainerColumn">
                                    <div>
                                        <Typography
                                            variant="h5"
                                            className={'emp-typography'}
                                        >
                                            Outcome:
                                        </Typography>
                                        <div className={'emp-fieldContainer'}>
                                            <SearchableSelect
                                                value={selectValues.verdict_id}
                                                onValueChange={(e) =>
                                                    handleSelectedValue(
                                                        'verdict_id',
                                                        e
                                                    )
                                                }
                                                options={
                                                    !isLoadingVerdict
                                                        ? verdictOptions ?? []
                                                        : []
                                                }
                                                accessor={'name'}
                                                testId={'outcome'}
                                                debounceDelay={200}
                                                hideSearch
                                                isPaginate={false}
                                                loadingValues={isLoadingVerdict}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                )}
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </StandardModal>
    )
}
