import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import {
    ProcessCellForExportParams,
    ShouldRowBeSkippedParams,
} from 'ag-grid-community'
import useIsMounted from 'hooks/utils/useIsMounted'

interface AGDataGridActionsProps {
    data: any
    dataSource: any
    isLoading: boolean
    pageSize: number
    page: number
    paginationTotal: number
    setLocalPage: (page: number) => void
    handlePageLimitChange: (pageSize: number) => void
    handleSearchField: (value: string) => void
    currentPayload: { [key: string]: any } | undefined
    refreshAGGrid: () => void
    csvContextMenuItem: (fileName: string) => void
    excelContextMenuItem: (fileName: string) => void
    resetDataGridContextMenuItem: (gridName: string) => void
}

export const useAGDataGridActions = (
    url: string,
    gridRef: any,
    activeNodeId: number | undefined,
    requestPayload: { [key: string]: any },
    {
        defaultLimit = 25,
        excludedExportColumns = ['select', 'actions'],
        handleFormatExportCells = undefined,
        handleSkipExportRow = undefined,
    }: {
        defaultLimit?: number
        excludedExportColumns?: string[]
        handleFormatExportCells?: (params: ProcessCellForExportParams) => void
        handleSkipExportRow?: (params: ShouldRowBeSkippedParams) => void
    }
): AGDataGridActionsProps => {
    const { isMounted } = useIsMounted()
    
    const [data, setData] = useState<{ [key: string]: any }[]>([])
    const [paginationTotal, setPaginationTotal] = useState<number>(0)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [localSearchValue, setLocalSearchValue] = useState<string>('')
    const [localPage, setLocalPage] = useState<number>(1)
    const [localPageSize, setLocalPageSize] = useState<number>(defaultLimit)
    const [localCurrentPayload, setLocalCurrentPayload] = useState(
        requestPayload
    )
    const localActiveNodeId = useRef(activeNodeId)
    const currentPayload = useRef(localCurrentPayload)
    const searchValue = useRef(localSearchValue)
    const pageSize = useRef(localPageSize)
    const page = useRef(1)

    const dataSource = {
        getRows(params: any) {
            setIsLoading(true)
            let payload: any = {
                limit: pageSize.current,
                start: pageSize.current * page.current - pageSize.current,
                sort_order: 'asc',
                search: searchValue.current ? searchValue.current : undefined,
                ...currentPayload.current,
            }
            const { filterModel, sortModel } = params.request

            //Sorting
            if (sortModel.length) {
                const { colId, sort } = sortModel[0]
                payload = {
                    ...payload,
                    sort_by: colId,
                    sort_order: sort,
                }
            }

            //Filtering
            const filterKeys = Object.keys(filterModel)
            filterKeys.forEach((filter) => {
                payload = {
                    ...payload,
                    [filter]: filterModel[filter].filter,
                }
            })

            axios
                .get(url, {
                    params: {
                        ...payload,
                    },
                })
                .then((response) => {
                    if (response.data.data) {
                        let { data: responseData, pagination } = response.data
                        if (
                            response.data.data.length &&
                            !response.data.data[0]?.id
                        ) {
                            responseData = response.data.data.map(
                                (
                                    value: { [key: string]: any },
                                    idx: number
                                ) => {
                                    return { rowIndex: idx, ...value }
                                }
                            )
                        }
                        const paginationTotal =
                            pagination?.total ?? responseData.length
                        setData(response.data)
                        setLocalCurrentPayload(payload)

                        params.success({
                            rowData: responseData,
                            rowCount: paginationTotal,
                        })
                        setPaginationTotal(paginationTotal)
                        if (paginationTotal) {
                            gridRef.current!.api!.hideOverlay()
                        }
                    } else {
                        let gridData = response.data

                        // If no 'id' property, then adding 'rowIndex' property to prevent errors with AG Grid's getRowId().
                        if (response.data.length && !response.data[0]?.id) {
                            gridData = response.data.map(
                                (
                                    value: { [key: string]: any },
                                    idx: number
                                ) => {
                                    return { rowIndex: idx, ...value }
                                }
                            )
                        }
                        setData(gridData)
                        setLocalCurrentPayload(payload)
                        params.success({
                            rowData: gridData,
                            rowCount: gridData.length,
                        })
                        setPaginationTotal(gridData.length)
                        if (gridData.length) {
                            gridRef.current!.api!.hideOverlay()
                        }
                    }
                })
                .catch(() => {
                    if (isMounted.current) {
                        // params.fail()
                        params.success({
                            rowData: [],
                            rowCount: 0,
                        })
                    }
                })
                .finally(() => {
                    if (isMounted.current) {
                        gridRef?.current!?.api!.refreshCells({
                            force: true,
                        })
                        setIsLoading(false)
                    }
                })
        },
    }

    const refreshAGGrid = () => {
        const api = gridRef?.current!?.api!
        if (api) {
            api.refreshServerSide()
            api.showLoadingOverlay()
        }
    }

    //Needed for Pagination
    useEffect(() => {
        pageSize.current = localPageSize
        localActiveNodeId.current = activeNodeId
        page.current =
            localActiveNodeId.current &&
            localActiveNodeId.current !== activeNodeId
                ? 1
                : localPage
        refreshAGGrid()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localPage, localPageSize])

    const handlePageLimitChange = (size: number) => {
        setLocalPageSize(size)
        setLocalPage(1)
    }

    //Needed for Search
    useEffect(() => {
        searchValue.current = localSearchValue
        localPage === 1 ? refreshAGGrid() : setLocalPage(1)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localSearchValue])

    const handleSearchField = (value: string) => {
        setLocalSearchValue(value)
    }

    //Needed for Payload Changes
    useEffect(() => {
        currentPayload.current = requestPayload
        localPage === 1 ? refreshAGGrid() : setLocalPage(1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestPayload])

    const csvContextMenuItem = (fileName: string) => {
        return {
            name: 'CSV Export',
            action: () => {
                gridRef.current!.api.exportDataAsCsv({
                    fileName: fileName,
                    sheetName: fileName,
                    columnKeys: gridRef
                        .current!.api?.getColumnDefs()
                        .filter(
                            (col: any) =>
                                !excludedExportColumns.includes(col.colId) &&
                                !col.hide
                        )
                        .map((col: any) => col.colId),
                    ...(handleFormatExportCells && {
                        processCellCallback: handleFormatExportCells,
                    }),
                    ...(handleSkipExportRow && {
                        shouldRowBeSkipped: handleSkipExportRow,
                    }),
                })
            },
            icon: '<i class="fa fa-file-csv-ag-grid"/>',
        }
    }

    const excelContextMenuItem = (fileName: string) => {
        return {
            name: 'Excel Export',
            action: () => {
                gridRef.current!.api.exportDataAsExcel({
                    fileName: fileName,
                    sheetName: fileName,
                    columnKeys: gridRef
                        .current!.api?.getColumnDefs()
                        .filter(
                            (col: any) =>
                                !excludedExportColumns.includes(col.colId) &&
                                !col.hide
                        )
                        .map((col: any) => col.colId),
                    ...(handleFormatExportCells && {
                        processCellCallback: handleFormatExportCells,
                    }),
                    ...(handleSkipExportRow && {
                        shouldRowBeSkipped: handleSkipExportRow,
                    }),
                })
            },
            icon: '<i class="fa fa-file-excel-ag-grid"/>',
        }
    }

    const resetDataGridContextMenuItem = (gridName: string) => {
        return {
            name: 'Reset Columns',
            action: () => {
                gridRef.current!.columnApi.resetColumnState()
                gridRef.current!.api.setFilterModel(null)
                gridRef.current!.api.deselectAll(null)
                localStorage.removeItem(`col-${gridName}`)
                localStorage.removeItem(`filter-${gridName}`)
            },
        }
    }

    return {
        data,
        dataSource,
        isLoading,
        pageSize: pageSize.current,
        page: page.current,
        paginationTotal,
        setLocalPage,
        handlePageLimitChange,
        handleSearchField,
        currentPayload: localCurrentPayload,
        refreshAGGrid,
        csvContextMenuItem,
        excelContextMenuItem,
        resetDataGridContextMenuItem,
    }
}
