import { CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import Amplify from 'aws-amplify'
import {
    ActiveMerchantProvider,
    CaseStatusPatchContext,
    ErrorBoundary,
    IdleUserBoundary,
    MainLayoutModal,
} from 'components'
// @ts-ignore
import { createBrowserHistory } from 'history'
import React from 'react'
import { Router } from 'react-router-dom'
import theme from 'theme'
import setupAxios from './api/BaseApiClient'
import awsconfig from './aws-exports'
import { AuthedUserProvider } from './context/AuthedUser/AuthedUserContext'
import Routes from './routes/Routes'
import './App.scss'
import { FeatureToggleProvider } from 'context/FeatureToggles/FeatureToggleContext'
import featuresconfig from 'features-exports'
import '../src/assets/icomoon/style.css'
import '../src/assets/fontFamily/worldpay/style.css'
import '../src/assets/fontAwesome/css/all.css'
import '../src/assets/fontFamily/googleFonts/style.css'
import { SWRConfig } from 'swr'
import { UiStateContextProvider } from 'context/UiState/UiStateContext'
import { useUiState } from 'hooks/cb-api/useUiState'
import { LicenseManager } from "ag-grid-enterprise";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Configure amplify from export settings
// Also check if SAML client ID present and swap out value of aws_user_pools_web_client_id when applicable.
Amplify.configure({
    ...awsconfig,
    aws_user_pools_web_client_id: awsconfig?.saml_web_client_id?.length
        ? awsconfig.saml_web_client_id
        : awsconfig.aws_user_pools_web_client_id
})

// This is needed to configure all default settings like authorization.
setupAxios()

const browserHistory = createBrowserHistory()

// Apply our AG-Grid license key.
LicenseManager.setLicenseKey('CompanyName=Chargebacks911,LicensedApplication=EMP,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-034615,SupportServicesEnd=3_November_2023_[v2]_MTY5ODk2OTYwMDAwMA==463866e7cc8de4a4355d0e8fdbdc539b');

function App() {
    const uiState = useUiState()

    return <>
        <CssBaseline />
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <ErrorBoundary area="App">
                    <FeatureToggleProvider features={featuresconfig}>
                        <Router history={browserHistory}>
                            <AuthedUserProvider>
                                <UiStateContextProvider value={uiState}>
                                    <IdleUserBoundary
                                        warningMinutes={14}
                                        timeoutMinutes={15}
                                    >
                                        <ActiveMerchantProvider>
                                            <CaseStatusPatchContext>
                                                <SWRConfig>
                                                    <MainLayoutModal />
                                                    <Routes />
                                                </SWRConfig>
                                            </CaseStatusPatchContext>
                                        </ActiveMerchantProvider>
                                    </IdleUserBoundary>
                                </UiStateContextProvider>
                            </AuthedUserProvider>
                        </Router>
                    </FeatureToggleProvider>
                </ErrorBoundary>
            </ThemeProvider>
        </StyledEngineProvider>
    </>;
}

export default App
