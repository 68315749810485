import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    CircularProgress,
} from '@mui/material'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

export interface AddCommentModalProps {
    openModal: boolean
    onClose: () => void
    onSubmit: (comment: string) => void
    loadingSubmit: boolean
    testId: string
    modalTitle?: string
    modalSubtitle?: string
    cancelButtonText?: string
    submitButtonText?: string
}

const AddCommentModal = ({
    openModal,
    onClose,
    onSubmit,
    loadingSubmit,
    testId,
    modalTitle = 'Add Comment',
    modalSubtitle = 'Comment',
    cancelButtonText = 'Cancel',
    submitButtonText = 'Submit',
}: AddCommentModalProps) => {
    const [comment, setComment] = useState<string>('')

    const handleOnClose = () => {
        onClose()
        setComment('')
    }

    return (
        <Dialog
            open={openModal}
            onClose={handleOnClose}
            maxWidth="sm"
            fullWidth
            scroll={'body'}
            id={`${testId}-${idDirectory.dialogMain}`}
        >
            <DialogContent
                className={'emp-addCommentModal-content'}
                id={`${testId}-${idDirectory.dialogContent}`}
            >
                <>
                    <h2
                        className={'emp-addCommentModal-title'}
                        id={`${testId}-${idDirectory.textTitle}`}
                    >
                        {modalTitle}
                    </h2>
                    <h4
                        className={'emp-addCommentModal-subtitle'}
                        id={`${testId}-${idDirectory.textSubtitle}`}
                    >
                        {modalSubtitle}
                    </h4>
                    <TextField
                        variant="outlined"
                        onChange={(e) => setComment(e.target.value)}
                        value={comment}
                        placeholder="Comment"
                        multiline
                        rows={4}
                        id={`${testId}-${idDirectory.inputTextField}`}
                    />
                    <div className={'emp-addCommentModal-actions'}>
                        <Button
                            className={'emp-addCommentModal-btn'}
                            variant="contained"
                            onClick={handleOnClose}
                            id={`${testId}-${idDirectory.btnCancel}`}
                            sx={clientTheme.buttons.defaultButton.style}
                        >
                            {cancelButtonText}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={'emp-addCommentModal-btn'}
                            onClick={() => {
                                onSubmit(comment)
                                setComment('')
                            }}
                            id={`${testId}-${idDirectory.btnSubmit}`}
                            disabled={loadingSubmit}
                        >
                            {loadingSubmit ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-addCommentModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                submitButtonText
                            )}
                        </Button>
                    </div>
                </>
            </DialogContent>
        </Dialog>
    )
}

export default AddCommentModal
