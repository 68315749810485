import React from 'react'
import { IconButton } from '@mui/material'
import assignUser from 'assets/icons/icon-assign-user.svg'
import unflagged from 'assets/icons/icon-flag--unflagged.svg'
import flagged from 'assets/icons/icon-flag.svg'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import { Icon as IconIcon } from 'components'
import { iconsTheme, clientTheme } from 'theme-exports'

interface BaseIconProps {
    src: string
    height?: number
    width?: number
    alt?: string
    className?: string
    id?: string
}

export const Icon = ({
    src,
    height = 20,
    width = 20,
    alt = 'icon',
    id = '',
}: BaseIconProps) => (
    <img
        height={height}
        width={width}
        src={src}
        alt={alt}
        {...(id && { id })}
    />
)

interface IconProps {
    onClick?: () => void
    assignEnabled?: boolean
    className?: string
    rowIndex?: number
    testId?: string
    disableRipple?: boolean
    selectedTabFilter?: string | number
}

interface FlagIconProps extends IconProps {
    isFlagged: boolean
}

export const FlagIcon = ({
    isFlagged,
    onClick,
    className = '',
    rowIndex,
    testId,
}: FlagIconProps) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    return (
        <IconButton
            onClick={onClick}
            className={className}
            disabled={isReadOnly}
            size="large">
            {iconsTheme.flagged ? (
                <IconIcon
                    className={`${
                        isFlagged ? iconsTheme.flagged : iconsTheme.unflagged
                    } `}
                    id={`${testId}-flag-icon-${rowIndex}`}
                />
            ) : (
                <Icon
                    src={isFlagged ? flagged : unflagged}
                    alt={isFlagged ? 'flagged' : 'unflagged'}
                    id={`${testId}-flag-icon-${rowIndex}`}
                />
            )}
        </IconButton>
    );
}

export const AssignUserIcon = ({
    onClick,
    assignEnabled = true,
    className = '',
    rowIndex,
    testId,
    disableRipple = false,
    selectedTabFilter = '',
}: IconProps) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    return (
        <IconButton
            onClick={onClick}
            className={className}
            disabled={!assignEnabled || isReadOnly}
            disableRipple={disableRipple}
            size="large"
        >
            {iconsTheme.assign ? (
                <IconIcon
                    className={
                        assignEnabled || selectedTabFilter === 'new_cases'
                            ? iconsTheme.assign
                            : iconsTheme.cross
                    }
                    id={`${testId}-assign-user-icon-${rowIndex}`}
                />
            ) : (
                <Icon
                    src={assignUser}
                    alt="assign user icon."
                    id={`${testId}-assign-user-icon-${rowIndex}`}
                />
            )}
        </IconButton>
    )
}

interface UploadIconProps extends IconProps {
    disabled?: boolean
    testId: string
}

export const UploadIcon = ({
    onClick = () => {},
    disabled = false,
    testId,
}: UploadIconProps) => {
    const { user } = useAuthedUser()
    const isReadOnly = user?.is_read_only

    return (
        <IconIcon
            onClick={disabled || isReadOnly ? () => {} : () => onClick()}
            className={`${
                disabled ? 'icon-upload-disabled' : 'icon-upload'
            } ${'emp-icons-uploadIcon-container'} ${
                disabled && 'emp-icons-uploadIcon-disabledIcon'
            } ${isReadOnly && 'emp-icons-uploadIcon-disabledColor'}`}
            id={testId}
            iconColor={clientTheme.icons.uploadIcon}
        />
    )
}

export default { FlagIcon }
