import React, { memo, useRef, useState } from 'react'
import {
    ValueFormatterParams,
    GetContextMenuItemsParams,
    ICellRendererParams,
} from 'ag-grid-community'
import {
    CaseMenu,
    CreditCardProcessorType,
    DataGridActions,
    Searchbar,
    VerdictType,
    AlertSnackbar,
    AGDataGrid,
} from 'components'
import { useColumnDefsAGGrid } from 'hooks/useColumnDefs/useColumnDefs'
import { useCases } from '../useCases'
import {
    ActionsCell,
    ViewDocumentIconAction,
    EditChargebackCell,
    IssuerDocsCell,
} from './Cells'
import { CaseData } from 'hooks/cb-api'
import { useAuthedUser } from 'context/AuthedUser/AuthedUserContext'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface ChargebacksGridProps {
    canViewPartnerCompanyId: boolean
    canViewIssuerDocs: boolean
    canViewOutcomeVerdicts: boolean
    canViewPlatform: boolean
    gridRef: any
    data: CaseData[]
    dataSource: any
    isLoading: boolean
    pageSize: number
    page: number
    paginationTotal: number
    setLocalPage: (page: number) => void
    handlePageLimitChange: (pageSize: number) => void
    handleSearchField: (value: string) => void
    currentPayload: { [key: string]: any } | undefined
    refreshChargebacksGrid: () => void
    getContextMenuItems: (params: GetContextMenuItemsParams) => void
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    isLoadingAdvFilters: boolean
}

export const amountValueFormatter = (value: string) => {
    return Number(value).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
}

export const ChargebacksGrid = ({
    canViewPartnerCompanyId,
    canViewIssuerDocs,
    canViewOutcomeVerdicts,
    canViewPlatform,
    gridRef,
    data,
    dataSource,
    isLoading,
    pageSize,
    page,
    paginationTotal,
    setLocalPage,
    handlePageLimitChange,
    handleSearchField,
    currentPayload,
    refreshChargebacksGrid,
    getContextMenuItems,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    isLoadingAdvFilters,
}: ChargebacksGridProps) => {
    const { user } = useAuthedUser()
    const { CHARGEBACK_EXPORT_HEADERS, DATA_GRID } = useFeatureToggle(
        'CHARGEBACKS'
    )
    const showContextMenu = DATA_GRID.CONTEXT_MENU?.enabled

    const tableOuterContainerEL = useRef<HTMLDivElement>(null)

    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>()

    const { colDefs: columns } = useColumnDefsAGGrid<CaseData>(
        'chargebacks',
        //@ts-ignore
        user?.role?.alias,
        {
            cells: {
                actions: memo((params: ValueFormatterParams) => (
                    <ActionsCell
                        rowData={params}
                        rehydrateView={refreshChargebacksGrid}
                        testId={'chargebacks'}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    />
                )),
                document: memo((params: ICellRendererParams) => (
                    <ViewDocumentIconAction
                        caseId={params.data.id}
                        disabled={!params.data.properties.is_doc_available}
                        rowIndex={params.rowIndex}
                        testId={'chargebacks'}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    />
                )),
                cc_type: memo((params: ValueFormatterParams) => (
                    <CreditCardProcessorType
                        processor={params.data.cc_type}
                        size="medium"
                        className={'emp-cardTypeIcon'}
                    />
                )),
                verdict: memo((params: ValueFormatterParams) => (
                    <VerdictType
                        verdict={params.data.verdict}
                        size="small"
                        className={'emp-verdictIcon'}
                    />
                )),
                dispute_amt: memo((params: ValueFormatterParams) => (
                    <span>{amountValueFormatter(params.data.dispute_amt)}</span>
                )),
                trans_amt: memo((params: ValueFormatterParams) => (
                    <span>{amountValueFormatter(params.data.trans_amt)}</span>
                )),
                edit: memo((params: ICellRendererParams) => (
                    <EditChargebackCell
                        rowData={params.data}
                        refreshGrid={refreshChargebacksGrid}
                        rowIndex={params.rowIndex}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    />
                )),
                issuer_doc: memo((params: ICellRendererParams) => (
                    <IssuerDocsCell
                        issuerDocs={params.data.issuer_docs ?? []}
                        caseId={params.data.id}
                        rowIndex={params.rowIndex}
                        testId={'chargebacks'}
                        setAlertSnackbarMainProps={setAlertSnackbarMainProps}
                        setAlertSnackbarMainOpen={setAlertSnackbarMainOpen}
                    />
                )),
                case_menu: memo((params: ICellRendererParams) => (
                    <CaseMenu
                        row={params.data}
                        rowIndex={params.rowIndex}
                        onCaseHistoryOpen={(rowIndex: number) =>
                            setSelectedRowIndex(rowIndex)
                        }
                        onCaseHistoryClose={() => setSelectedRowIndex(null)}
                        caseHistoryDisplayType={'popover'}
                        testId={'chargebacks'}
                        className={'emp-caseMenuIcon'}
                    />
                )),
                is_credit: memo((params: ValueFormatterParams) => (
                    <span>{params.data.is_credit ? 'Yes' : 'No'}</span>
                )),
                is_guaranteed: memo((params: ValueFormatterParams) => (
                    <span>{params.data.is_guaranteed ? 'Yes' : 'No'}</span>
                )),
            },
        }
    )

    const {
        download,
        isLoadingDownloadExportAll,
        isLoadingDownloadCsv,
        isEmailExport,
        closeEmailExport,
        downloadXlsx,
        isLoadingDownloadXlsx,
        isLoadingDownloadXlsxAll,
        downloadError,
        downloadErrorString,
        closeDownloadError,
    } = useCases('Chargebacks', gridRef, CHARGEBACK_EXPORT_HEADERS.headers)

    const filteredColumns = columns.filter((i) => {
        if (i.accessor === 'partner_company_id') {
            return canViewPartnerCompanyId
        }
        if (i.accessor === 'issuer_doc') {
            return canViewIssuerDocs
        }
        if (i.accessor === 'verdict') {
            return canViewOutcomeVerdicts
        }
        if (i.accessor === 'processor') {
            return canViewPlatform
        }
        return true
    })

    const defaultColDef = {
        flex: 1,
        minWidth: 120,
        resizable: true,
    }

    return (
        <div id={'chargebacksGridAG'}>
            <div className={'emp-searchbarContainer'}>
                <Searchbar
                    className={'emp-cssOutlinedInput'}
                    debounceDelay={200}
                    returnValue={handleSearchField}
                    testId={'chargebacks'}
                />
                <DataGridActions
                    printId="cb-cases-dg"
                    tableData={data}
                    dataActionPDF={true}
                    dataActionExportAllExcel={true}
                    dataActionSwitchGrids={true}
                    dataForExportAll={[]}
                    columns={filteredColumns}
                    dataActionColumnFilter={false}
                    onExportAll={() => {
                        // exports total entries
                        download(
                            {
                                export_total: paginationTotal,
                                ...currentPayload,
                            },
                            true
                        )
                    }}
                    onExportAllLoading={isLoadingDownloadExportAll}
                    onExportCsv={() => {
                        // exports total entries
                        download({
                            export_type: 'page',
                            export_total: paginationTotal,
                            ...currentPayload,
                        })
                    }}
                    onExportCsvLoading={isLoadingDownloadCsv}
                    onExportXlsx={() =>
                        downloadXlsx(
                            {
                                export_type: 'page',
                                export_total: paginationTotal,
                                ...currentPayload,
                            },
                            true
                        )
                    }
                    onExportXlsxLoading={isLoadingDownloadXlsx}
                    onExportAllXlsx={() =>
                        downloadXlsx({
                            export_total: paginationTotal,
                            ...currentPayload,
                        })
                    }
                    onExportAllXlsxLoading={isLoadingDownloadXlsxAll}
                    fileName={'Chargebacks'}
                    testId={'chargebacks'}
                />
            </div>
            <div ref={tableOuterContainerEL}>
                <AGDataGrid
                    gridRef={gridRef}
                    gridName={'chargebacks'}
                    fileName={'chargebacks'}
                    columns={filteredColumns}
                    dataSource={dataSource}
                    loading={isLoading || isLoadingAdvFilters}
                    testId={'chargebacks'}
                    onPageLimitChange={handlePageLimitChange}
                    pageSize={pageSize}
                    page={page}
                    onPageChange={setLocalPage}
                    totalEntries={paginationTotal}
                    defaultColDef={defaultColDef}
                    getCustomContextMenuItems={getContextMenuItems}
                    enableCustomContextMenuItems={showContextMenu}
                    enableSelectAllCheckbox={showContextMenu}
                    enableSelectOneCheckbox={showContextMenu}
                    rowSelectionType={'multiple'}
                    disableRowClickSelection
                    selectedRowIndex={selectedRowIndex}
                    fullHeightContainerRef={tableOuterContainerEL}
                    refreshDataGrid={refreshChargebacksGrid}
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                />
            </div>
            <AlertSnackbar
                content={{
                    message:
                        'This may take up to 15 minutes. We will send you an email with a link to download your export when it’s ready. Thank you!',
                    title: 'Export started.',
                    intent: 'success',
                }}
                onClose={closeEmailExport}
                autoHideDuration={30_000}
                open={isEmailExport}
                showCloseIcon
            />
            <AlertSnackbar
                content={{
                    message: downloadErrorString,
                    title: 'Error.',
                    intent: 'error',
                }}
                onClose={closeDownloadError}
                open={downloadError}
                showCloseIcon
            />
        </div>
    )
}
