import React from 'react'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress,
    IconButton,
} from '@mui/material'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

export interface IMessageModalProps {
    openModal: boolean
    onModalClose: () => void
    testId: string
    modalHeader?: string
    modalSubHeader?: string | null
    modalMessage?: string | null
    children?: React.ReactNode | null
    maxModalWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
    showAcceptActionBtn?: boolean
    acceptBtnText?: string
    handleAcceptActionBtn?: () => void
    disableAcceptActionBtn?: boolean
    isLoadingAcceptActionBtn?: boolean
    showDenyActionBtn?: boolean
    denyBtnText?: string
    handleDenyActionBtn?: () => void
    disableDenyActionBtn?: boolean
    isLoadingDenyActionBtn?: boolean
    showCloseActionBtn?: boolean
    closeBtnText?: string
    handleCloseActionBtn?: () => void
    disableCloseActionBtn?: boolean
    isLoadingCloseActionBtn?: boolean
    disableBackdropClick?: boolean
    className?: string
}

const MessageModal = ({
    openModal,
    onModalClose,
    testId,
    modalHeader = 'Update',
    modalSubHeader = null,
    modalMessage = null,
    children = null,
    maxModalWidth = 'md',
    showAcceptActionBtn = true,
    acceptBtnText = 'Accept',
    handleAcceptActionBtn = () => {},
    disableAcceptActionBtn = false,
    isLoadingAcceptActionBtn = false,
    showDenyActionBtn = true,
    denyBtnText = 'Deny',
    handleDenyActionBtn = () => {},
    disableDenyActionBtn = false,
    isLoadingDenyActionBtn = false,
    showCloseActionBtn = true,
    closeBtnText = 'Close',
    handleCloseActionBtn = () => {},
    disableCloseActionBtn = false,
    isLoadingCloseActionBtn = false,
    disableBackdropClick = true,
    className = '',
}: IMessageModalProps): React.ReactElement => {
    const sanitizedHTMLModalHeader = DOMPurify.sanitize(modalHeader ?? '')
    const parsedHTMLModalHeader = parse(sanitizedHTMLModalHeader)

    const sanitizedHTMLModalSubHeader = DOMPurify.sanitize(modalSubHeader ?? '')
    const parsedHTMLModalSubHeader = parse(sanitizedHTMLModalSubHeader)

    const sanitizedHTMLModalMsg = DOMPurify.sanitize(modalMessage ?? '')
    const parsedHTMLModalMsg = parse(sanitizedHTMLModalMsg)

    return (
        <Dialog
            fullWidth
            open={openModal}
            onClose={disableBackdropClick ? () => {} : onModalClose}
            className={className}
            maxWidth={maxModalWidth}
            id={`${idDirectory.divRoot}-${testId}`}
            scroll={'body'}
        >
            <DialogTitle
                className={`${'emp-messageModal-dialogTitle'} `}
                id={`${idDirectory.divTitle}-${testId}`}
            >
                <div className={'emp-messageModal-modalHeader'}>
                    {parsedHTMLModalHeader}
                </div>
                <IconButton
                    size="small"
                    onClick={onModalClose}
                    className={'emp-messageModal-modalClose'}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </IconButton>
            </DialogTitle>
            <DialogContent
                className={'emp-messageModal-dialogContent'}
                id={`${idDirectory.divContent}-${testId}`}
            >
                {modalSubHeader && (
                    <div className={'emp-messageModal-modalSubHeader'}>
                        {parsedHTMLModalSubHeader}
                    </div>
                )}
                {modalMessage && (
                    <div className={'emp-messageModal-modalMessage'}>
                        {parsedHTMLModalMsg}
                    </div>
                )}
                {children && <div>{children}</div>}
            </DialogContent>
            {(showAcceptActionBtn ||
                showCloseActionBtn ||
                showDenyActionBtn) && (
                <DialogActions
                    className={'emp-messageModal-dialogActions'}
                    id={`${idDirectory.divActions}-${testId}`}
                >
                    {showCloseActionBtn && (
                        <Button
                            variant="contained"
                            sx={clientTheme.buttons.defaultButton.style}
                            onClick={handleCloseActionBtn}
                            id={`${idDirectory.btnClose}-${testId}`}
                            disabled={disableCloseActionBtn}
                        >
                            {isLoadingCloseActionBtn ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-messageModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                closeBtnText
                            )}
                        </Button>
                    )}
                    {showDenyActionBtn && (
                        <Button
                            variant="contained"
                            onClick={handleDenyActionBtn}
                            color={'primary'}
                            id={`${idDirectory.btnDeny}-${testId}`}
                            disabled={disableDenyActionBtn}
                        >
                            {isLoadingDenyActionBtn ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-messageModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                denyBtnText
                            )}
                        </Button>
                    )}
                    {showAcceptActionBtn && (
                        <Button
                            variant="contained"
                            onClick={handleAcceptActionBtn}
                            color={'secondary'}
                            id={`${idDirectory.btnAccept}-${testId}`}
                            disabled={disableAcceptActionBtn}
                        >
                            {isLoadingAcceptActionBtn ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-messageModal-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                acceptBtnText
                            )}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Dialog>
    )
}

export default MessageModal
