import BaseService from './BaseService'
import { CasesService } from './Cases'
import {
    DocumentsService,
    DocumentsCsvService,
    DocumentsErtService,
    DocumentsCaseBuilderService,
    DocumentsCaseUpdaterService,
} from './Documents'
import { MerchantService } from './Merchants'
import { AuthService } from './Auth'
import { MidService } from './Mids/'
import UserService from './Users/Users'
import ReportService from './Reports/Reports'
import { GenericService } from './Generic'
import { ProcessService } from './Process/Process'
// Tools
export { Tools as CBtools } from './Tools'
// Tracking
export { NewRelic as CBNewRelic } from './Tracking'

const bootstrap = (Service: typeof BaseService) => new Service()

export default class CB {
    /** */
    static documents = bootstrap(DocumentsService) as DocumentsService

    /** */
    static documentsCsv = bootstrap(DocumentsCsvService) as DocumentsCsvService

    /** */
    static documentsErt = bootstrap(DocumentsErtService) as DocumentsErtService

    /** */
    static documentsCaseUpdater = bootstrap(
        DocumentsCaseUpdaterService
    ) as DocumentsCaseUpdaterService

    /** */
    static documentsCaseBuilder = bootstrap(
        DocumentsCaseBuilderService
    ) as DocumentsCaseBuilderService

    /** */
    static cases = bootstrap(CasesService) as CasesService

    /** */
    static merchants = bootstrap(MerchantService) as MerchantService

    /** */
    static auth = bootstrap(AuthService) as AuthService

    /** */
    static mids = bootstrap(MidService) as MidService

    /** */
    static users = bootstrap(UserService) as UserService

    /** */
    static reports = bootstrap(ReportService) as ReportService

    /** */
    static generic = bootstrap(GenericService) as GenericService

    /** */
    static process = bootstrap(ProcessService) as ProcessService
}
