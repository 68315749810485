import React from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    CircularProgress,
} from '@mui/material'
import { clientTheme } from 'theme-exports'
import idDirectory from './idAttributes'

export interface IConfirmActionModalProps {
    open: boolean
    toggleOpen: () => void
    onConfirm: () => void
    testId: string
    header?: string
    message?: string
    cancelButtonText?: string
    confirmButtonText?: string
    inactivateConfirmButton?: boolean
    isLoadingConfirmButton?: boolean
    additionalContent?: React.ReactNode
    maxModalWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs' | false
    enableConfirmActionModalSlim?: boolean
    className?: string
}

const ConfirmActionModal = ({
    open,
    toggleOpen,
    onConfirm,
    header = 'Confirmation needed',
    message = 'Are you sure?',
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
    inactivateConfirmButton = false,
    isLoadingConfirmButton = false,
    testId = '',
    additionalContent = null,
    maxModalWidth = 'xs',
    enableConfirmActionModalSlim = false,
    className = '',
}: IConfirmActionModalProps) => {
    return (
        <Dialog
            fullWidth
            open={open}
            className={className}
            maxWidth={maxModalWidth}
            scroll={'body'}
            id={`${idDirectory.divRoot}-${testId}`}
        >
            <div
                className={'emp-confirmActionModal-dialogHeaderContainer'}
                id={`${idDirectory.divHeader}-${testId}`}
            >
                <div className={'emp-confirmActionModal-exlamationIcon'}>
                    <div>!</div>
                </div>

                <div
                    className={
                        enableConfirmActionModalSlim
                            ? 'emp-confirmActionModal-dialogTitleSlim'
                            : 'emp-confirmActionModal-dialogTitle'
                    }
                >
                    {header}
                </div>
            </div>

            <DialogContent
                className={'emp-confirmActionModal-dialogContent'}
                id={`${idDirectory.divContent}-${testId}`}
            >
                <div
                    className={
                        enableConfirmActionModalSlim
                            ? 'emp-confirmActionModal-dialogContentTextSlim'
                            : 'emp-confirmActionModal-dialogContentText'
                    }
                >
                    {message}
                </div>

                {additionalContent && <div>{additionalContent}</div>}
            </DialogContent>

            <DialogActions
                className={'emp-confirmActionModal-dialogActions'}
                id={`${idDirectory.divActions}-${testId}`}
            >
                <Button
                    variant="contained"
                    onClick={toggleOpen}
                    id={`${idDirectory.btnCancel}-${testId}`}
                    sx={clientTheme.buttons.defaultButton.style}
                >
                    {cancelButtonText}
                </Button>
                <Button
                    color="secondary"
                    variant="contained"
                    onClick={onConfirm}
                    id={`${idDirectory.btnConfirm}-${testId}`}
                    disabled={inactivateConfirmButton}
                >
                    {isLoadingConfirmButton ? (
                        <>
                            Submitting...{' '}
                            <CircularProgress
                                className={'emp-standardModal-circularProgress'}
                                color="secondary"
                            />
                        </>
                    ) : (
                        confirmButtonText
                    )}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmActionModal
