import axios from 'axios'

export class NewRelic {
    static sendNewRelicEvent(info: {
        key: string
        case_id?: string | number | null
        event_name: string
        event_action: string
        event_message?: string
    }) {
        const payloadToSend = {
            key: info.key,
            case: {
                id: info?.case_id ?? null,
            },
            event: {
                name: info.event_name,
                action: info.event_action,
                message: info?.event_message ?? '',
            },
        }

        return axios.post(`/users/events/tracking`, payloadToSend)
    }
}
