import Set from 'components/Set/Set'
import MainLayout from 'layouts/MainLayout/MainLayout'
import React, { Suspense, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
// High priority utility views are imported directly
import { Dashboard as DashboardView, Loading, NotFound } from 'views'
import useFeatureToggle from 'hooks/FeatureToggles/useFeatureToggles'
// Lazy load almost all the routes except dashboard, and other high priority utility views
// const PlaceholderView = React.lazy(
//     () => import('views/_placeholders/Placeholder')
// )
const HelpView = React.lazy(() => import('views/Help'))
const ChargebacksView = React.lazy(() => import('views/Chargebacks'))
// const ChargebacksAGGridView = React.lazy(
//     () => import('views/ChargebacksAGGrid/Chargebacks')
// )
const SettingsView = React.lazy(() => import('views/Settings'))
// const SettingsAGGridView = React.lazy(() => import('views/SettingsAGGrid'))
const UsersView = React.lazy(() => import('views/Users/'))
// const UsersAGGridView = React.lazy(() => import('views/UsersAGGrid/Users'))
const ReportsView = React.lazy(() => import('views/Reports/Reports'))
const MTDReportsView = React.lazy(() => import('views/MTDReports/MTDReports'))
const AddEditUserView = React.lazy(() => import('views/AddEditUser/AddEditUser'))
const MidHealthView = React.lazy(() => import('views/MidHealth/MidHealth'))
// const MidHealthAGGridView = React.lazy(
//     () => import('views/MidHealthAGGrid/MidHealth')
// )
const ResultantKpiView = React.lazy(
    () => import('views/ResultantKpi/ResultantKpi')
)
const TemplateLibraryView = React.lazy(() => import('views/TemplateLibrary/TemplateLibrary'))
// const TemplateLibraryAGGridView = React.lazy(
//     () => import('views/TemplateLibraryAGGrid/TemplateLibrary')
// )
const MerchantHierarchyView = React.lazy(
    () => import('views/MerchantHierarchy')
)
const MerchantSettingsView = React.lazy(() => import('views/MerchantSettings'))
const CreateMerchant = React.lazy(
    () => import('views/AddEditMerchant/AddEditMerchant')
)
const CaseManagementLegacyView = React.lazy(
    () => import('views/CaseManagement/CaseManagement')
)
const CaseManagementView = React.lazy(
    () => import('views/CaseManagementAGGrid/CaseManagement')
)
const ErtLegacyView = React.lazy(() => import('views/ERT'))
const ErtView = React.lazy(() => import('views/ERTAGGrid'))

const AlertsView = React.lazy(() => import('views/Alerts/pages/AlertsPage'))
const AlertsAGGridView = React.lazy(() => import('views/AlertsAGGrid/Alerts'))
const AlertsIFrameView = React.lazy(() => import('views/AlertsIFrame'))

const PreChargebacksView = React.lazy(() => import('views/Alerts/pages/PreChargebacksPage'))
const PreChargebacksAGGridView = React.lazy(() => import('views/PreChargebacksAGGrid'))
const PreChargebacksIFrameView = React.lazy(() => import('views/PreChargebacksIFrame'))

const ReconciliationReportView = React.lazy(
    () => import('views/ReconciliationReport/ReconciliationReport')
)
// const ReconciliationReportAGGridView = React.lazy(
//     () => import('views/ReconciliationReportAGGrid/ReconciliationReport')
// )
const AlertsReportView = React.lazy(
    () => import('views/AlertsReport/AlertsReport')
)
const OnboardingLegacyView = React.lazy(() => import('views/Onboarding/Onboarding'))
const OnboardingView = React.lazy(
    () => import('views/OnboardingAGGrid/Onboarding')
)
const EditOnboardingLegacyView = React.lazy(
    () => import('views/Onboarding/EditOnboarding')
)
const EditOnboardingView = React.lazy(
    () => import('views/OnboardingAGGrid/EditOnboarding/EditOnboarding')
)
const CaseQCReviewView = React.lazy(
    () => import('views/CaseQCReview/CaseQCReview')
)
const QCReviewView = React.lazy(() => import('views/QCReview/QCReview'))
const InvalidChargebacksView = React.lazy(
    () => import('views/InvalidChargebacks/InvalidChargebacks')
)
// const InvalidChargebacksAGGridView = React.lazy(
//     () => import('views/InvalidChargebacksAGGrid/InvalidChargebacks')
// )
// const QCReviewAGGridView = React.lazy(
//     () => import('views/QCReviewAGGrid/QCReview')
// )
const AdminReviewLegacyView = React.lazy(
    () => import('views/AdminReview/AdminReview')
)
const AdminReviewView = React.lazy(
    () => import('views/AdminReviewAGGrid/AdminReview')
)
const CaseAdminReviewView = React.lazy(
    () => import('views/CaseAdminReview/CaseAdminReview')
)
const WebhooksView = React.lazy(() => import('views/Webhooks/Webhooks'))
const APIDocumentationView = React.lazy(
    () => import('views/APIDocumentation/APIDocumentation')
)
const DownloadsView = React.lazy(() => import('views/Downloads/Downloads'))
const CaseUpdaterLegacyView = React.lazy(
    () => import('views/CaseUpdater/CaseUpdater')
)
const CaseUpdaterView = React.lazy(
    () => import('views/CaseUpdaterAGGrid/CaseUpdater')
)
const MonitoringView = React.lazy(
    () => import('views/Monitoring/Monitoring')
)
/**
 * Used to set up new routes (not their navlinks or permissions, though)
 */

const Routes: React.FC = () => {
    const location = useLocation()
    const {
        enabled: alertsIFrameEnabled
    } = useFeatureToggle('ALERTS_IFRAME')

    // Adds ID attribute to <body> needed for CSS.
    useEffect(() => {
        const currentPath = location.pathname
        const convertPathToID =
            currentPath
                .replace('/', '')
                .replace(/[0-9]/g, '')
                .toLowerCase()
                .replace(/([-_/][a-z])/g, (group) =>
                    group
                        .toUpperCase()
                        .replace('-', '')
                        .replace('_', '')
                        .replace('/', '')
                )
                .replaceAll('/', '') + 'View'

        document.body.setAttribute('id', convertPathToID || '')
    }, [location])

    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                <Set wrap={[MainLayout]}>
                    <Route
                        exact
                        path="/"
                        component={() => <Redirect to="/dashboard" />}
                    />
                    <Route
                        exact
                        path="/login"
                        component={() => <Redirect to="/dashboard" />}
                    />
                    <Route component={DashboardView} exact path="/dashboard" />
                    <Route
                        component={ChargebacksView}
                        exact
                        path="/chargebacks"
                    />
                    {/* <Route
                        component={ChargebacksAGGridView}
                        exact
                        path="/chargebacks-new"
                    /> */}
                    {alertsIFrameEnabled ?
                        <>
                            <Route component={AlertsIFrameView} exact path="/alerts" />
                            <Route component={PreChargebacksIFrameView} exact path="/pre-chargebacks" />
                        </>
                    :
                        <>
                            <Route component={AlertsView} exact path="/alerts" />
                            <Route component={PreChargebacksView} exact path="/pre-chargebacks" />
                            <Route
                                component={AlertsAGGridView}
                                exact
                                path="/alerts-new"
                            />
                            <Route
                                component={PreChargebacksAGGridView}
                                exact
                                path="/pre-chargebacks-new"
                            />
                        </>
                    }
                    <Route
                        component={CaseManagementView}
                        exact
                        path="/case-management"
                    />
                    <Route
                        component={CaseManagementLegacyView}
                        exact
                        path="/case-management-legacy"
                    />
                    <Route
                        component={CaseUpdaterView}
                        exact
                        path="/case-updater"
                    />
                    <Route
                        component={CaseUpdaterLegacyView}
                        exact
                        path="/case-updater-legacy"
                    />
                    <Route
                        component={MonitoringView}
                        exact
                        path="/reports/monitoring"
                    />
                    <Route component={ReportsView} exact path="/reports" />
                    <Route component={MTDReportsView} exact path="/mtd" />
                    <Route component={ErtView} exact path="/ert" />
                    <Route component={ErtLegacyView} exact path="/ert-legacy" />

                    <Route
                        component={ResultantKpiView}
                        exact
                        path="/resultant-kpi"
                    />
                    <Route component={MidHealthView} exact path="/mid-health" />
                    {/* <Route
                        component={MidHealthAGGridView}
                        exact
                        path="/mid-health-new"
                    /> */}
                    <Route
                        component={ReconciliationReportView}
                        exact
                        path="/reconciliation-report"
                    />
                    {/* <Route
                        component={ReconciliationReportAGGridView}
                        exact
                        path="/reconciliation-report-new"
                    /> */}
                    <Route
                        component={AlertsReportView}
                        exact
                        path="/alerts-reporting"
                    />
                    <Route component={UsersView} exact path="/users" />
                    {/* <Route
                        component={UsersAGGridView}
                        exact
                        path="/users-new"
                    /> */}
                    <Route component={SettingsView} exact path="/settings" />
                    {/* <Route
                        component={SettingsAGGridView}
                        exact
                        path="/settings-new"
                    /> */}
                    <Route
                        component={MerchantHierarchyView}
                        exact
                        path="/merchant-hierarchy"
                    />
                    <Route
                        component={MerchantSettingsView}
                        exact
                        path="/merchant-settings"
                    />
                    <Route
                        component={CreateMerchant}
                        exact
                        path="/merchant/update/:merchantId"
                    />
                    <Route component={HelpView} exact path="/help" />
                    <Route
                        component={TemplateLibraryView}
                        exact
                        path="/template-library"
                    />
                    {/* <Route
                        component={TemplateLibraryAGGridView}
                        exact
                        path="/template-library-new"
                    /> */}
                    <Route
                        component={AddEditUserView}
                        exact
                        path="/user/update/"
                    />
                    <Route
                        component={AddEditUserView}
                        exact
                        path="/user/update/:userId"
                    />
                    <Route
                        component={AddEditUserView}
                        exact
                        path="/settings/profile"
                    />
                    <Route
                        component={OnboardingView}
                        exact
                        path="/onboarding"
                    />
                    <Route
                        component={OnboardingLegacyView}
                        exact
                        path="/onboarding-legacy"
                    />
                    <Route
                        component={EditOnboardingView}
                        exact
                        path="/onboarding/edit/:onboardingId"
                    />
                    <Route
                        component={EditOnboardingLegacyView}
                        exact
                        path="/onboarding-legacy/edit/:onboardingId"
                    />
                    <Route
                        component={CaseQCReviewView}
                        exact
                        path="/case-qc-review/view-case/:caseId?"
                    />
                    <Route
                        component={QCReviewView}
                        exact
                        path="/case-qc-review"
                    />
                    <Route
                        component={CaseAdminReviewView}
                        exact
                        path="/review/view-case/:caseId?"
                    />
                    <Route component={AdminReviewView} exact path="/review" />
                    <Route
                        component={AdminReviewLegacyView}
                        exact
                        path="/review-legacy"
                    />
                    {/* <Route
                        component={QCReviewAGGridView}
                        exact
                        path="/case-qc-review-new"
                    /> */}
                    <Route
                        component={InvalidChargebacksView}
                        exact
                        path="/invalid-chargebacks"
                    />
                    {/* <Route
                        component={InvalidChargebacksAGGridView}
                        exact
                        path="/invalid-chargebacks-new"
                    /> */}
                    <Route component={WebhooksView} exact path="/webhooks" />
                    <Route
                        component={APIDocumentationView}
                        exact
                        path="/resource-api-documentation"
                    />
                    <Route component={DownloadsView} exact path="/download" />
                    <Route component={NotFound} exact path="/not-found" />
                </Set>
                <Route
                    path="*"
                    component={() => <Redirect to="/not-found" />}
                />
            </Switch>
        </Suspense>
    )
}

export default Routes
