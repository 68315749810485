import React from 'react'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dialog, Tooltip, CircularProgress } from '@mui/material'
import { SearchableSelect } from 'components'
import { clientTheme } from 'theme-exports'
import { useAssignUser, ModalVariant, ModalMode } from './useAssignUser'
import { CaseData } from 'hooks/cb-api'
import { useSWRContext } from 'context/SWRContext'
import { alertSnackbarContentProps } from 'components/AlertSnackbar'

interface AssignUserProps {
    open: boolean
    toggleOpen: (variant: ModalVariant, mode?: ModalMode) => void
    isMultiAssign?: boolean
    selectedCaseIds: number[]
    selectedCaseMerchantId: number | null
    row?: CaseData
    rehydrateView: () => void
    unassign?: boolean
    cases: any[]
    enableAdminUsersOnly?: boolean
    isAdminReview?: boolean
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    unselectAllRows?: () => void
}

/**
 * @name AssignUser
 * @description - The AssignUser modal component consists of a searchable select input for users, and the ability to assign a user to case(s)
 * @param open - bool that opens or closes the modal view
 * @param toggleOpen -  takes in a variant and toggles the modal open
 * @param isMultiAssign - bool that tells AssignUser rather it was opened with multiple case selections or just a single
 * @param selectedCaseIds - array of selected case ids should any be selected in the datagrid
 * @param caseModel - optional - if the AssignUser was opened in single select from a datagrid action cell it will be passed a caseModel
 * @param rehydrateView - reloads datagrid
 * @param unassign - switches the modal to unassign
 * @param isAdminReview - Admin Review calls different endpoints.
 */
const AssignUser = ({
    cases,
    open = false,
    toggleOpen,
    selectedCaseIds,
    selectedCaseMerchantId,
    row,
    rehydrateView,
    unassign = false,
    enableAdminUsersOnly = false,
    isAdminReview = false,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    unselectAllRows = () => {},
}: AssignUserProps) => {

    const {
        users,
        selectedUser,
        setSelectedUser,
        handleChangeValue,
        handleSearch,
        searchValue,
        isLoadingAssign,
        isLoadingUnassign,
        handleBulkAssignCase,
        handleBulkUnassignCase,
        handleBulkAssignAdminReview,
        handleBulkUnassignAdminReview,
        handleAssignCase,
        handleUnassignCase,
        handleAssignAdminReview,
        handleUnassignAdminReview,
    } = useAssignUser(
        cases,
        enableAdminUsersOnly,
        setAlertSnackbarMainProps,
        setAlertSnackbarMainOpen,
        toggleOpen,
        unselectAllRows,
        selectedCaseMerchantId
    )

    const { swrActions } = useSWRContext()

    const { caseViewActions } = swrActions

    const { getAssignedName } = caseViewActions

    const isMultiAssign = Boolean(selectedCaseIds.length)

    return (
        <Dialog open={open}>
            <div className={'emp-assignUser-headerBanner'}>
                <span>
                    {isMultiAssign ? 'Bulk Assign Cases' : 'Assign Case'}
                </span>
                {open && (
                    <div>
                        <Tooltip placement="bottom" title="Close">
                            <span
                                onClick={() => {
                                    toggleOpen('assignUser')
                                    setSelectedUser(null)
                                }}
                                style={{ cursor: 'pointer' }}
                            >
                                <FontAwesomeIcon icon={faTimes} />
                            </span>
                        </Tooltip>
                    </div>
                )}
            </div>
            <div className={'emp-assignUser-modal'}>
                <div className={'emp-assignUser-assignInput'}>
                    <label>Assign To:</label>
                    <div className={'emp-assignUser-selectInput'}>
                        <SearchableSelect
                            value={
                                selectedUser
                                    ? getAssignedName(selectedUser)
                                    : ''
                            }
                            onValueChange={(opt: any) =>
                                handleChangeValue(opt?.id)
                            }
                            searchValue={searchValue}
                            onSearchChange={handleSearch}
                            options={users as any}
                            accessor="username"
                        />
                    </div>
                </div>
                <div className={'emp-assignUser-btnGrp'}>
                    <Button
                        variant="contained"
                        className={`emp-assignUser-btn`}
                        onClick={() => {
                            toggleOpen('assignUser')
                            setSelectedUser(null)
                        }}
                        sx={clientTheme.buttons.defaultButton.style}
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        className={'emp-assignUser-btn'}
                        disabled={!selectedUser || isLoadingAssign}
                        onClick={() => {
                            if (isAdminReview) {
                                if (isMultiAssign)
                                    handleBulkAssignAdminReview(selectedCaseIds)
                                else
                                    handleAssignAdminReview(
                                        selectedUser?.id,
                                        row?.id
                                    )
                            } else {
                                if (isMultiAssign)
                                    handleBulkAssignCase(selectedCaseIds)
                                else handleAssignCase(selectedUser?.id, row?.id)
                            }
                        }}
                    >
                        {isLoadingAssign ? (
                            <>
                                Submitting...{' '}
                                <CircularProgress
                                    className={
                                        'emp-assignUser-circularProgress'
                                    }
                                    color="secondary"
                                />
                            </>
                        ) : (
                            'Assign'
                        )}
                    </Button>
                    {unassign ? (
                        <Button
                            variant="contained"
                            className={'emp-assignUser-btn'}
                            style={{ background: '#D62928', color: '#fff' }}
                            onClick={() => {
                                if (isAdminReview) {
                                    if (isMultiAssign)
                                        handleBulkUnassignAdminReview(
                                            selectedCaseIds
                                        )
                                    else
                                        handleUnassignAdminReview(
                                            false,
                                            row?.id
                                        )
                                } else {
                                    if (isMultiAssign)
                                        handleBulkUnassignCase(selectedCaseIds)
                                    else handleUnassignCase(false, row?.id)
                                }
                            }}
                            disabled={isLoadingUnassign}
                        >
                            {isLoadingUnassign ? (
                                <>
                                    Submitting...{' '}
                                    <CircularProgress
                                        className={
                                            'emp-assignUser-circularProgress'
                                        }
                                        color="secondary"
                                    />
                                </>
                            ) : (
                                'Unassign'
                            )}
                        </Button>
                    ) : null}
                </div>
            </div>
        </Dialog>
    )
}

export default AssignUser
