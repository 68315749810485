/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash.debounce'
import { TextField, Typography, Button, Autocomplete } from '@mui/material/'
import UsersApi from '../../../src/api/UsersApi'
import AlertSnackbar, {
    alertSnackbarContentProps,
} from 'components/AlertSnackbar'

interface MidPermission {
    name: string
    value: string
    id: number | string
}

interface IMidPermissions extends React.HTMLAttributes<HTMLDivElement> {
    label: string
    variant?: 'outlined' | 'filled' | 'standard'
    midPermissions?: MidPermission[]
    permissionsDefault?: MidPermission[]
    merchantId?: number
    userId?: number
    setAlertSnackbarMainProps: (value: alertSnackbarContentProps) => void
    setAlertSnackbarMainOpen: (status: boolean) => void
    setModalOpen: (open: boolean) => void
}

/**
 * @description Shows a Mid Permissions autocomplete input that can be passed as a child into another component, for example in Modal Area.
 */
const MidPermissions = ({
    label,
    className = '',
    variant = 'outlined',
    merchantId = 0,
    userId = 0,
    setAlertSnackbarMainProps,
    setAlertSnackbarMainOpen,
    setModalOpen,
}: IMidPermissions): React.ReactElement => {
    const [midSearchTextField, setMidSearchTextField] = useState<string>('')
    const [midSearchValue, setMidSearchValue] = useState<string>('')
    const [isLoadingMids, setIsLoadingMids] = useState<boolean>(false)
    const [allMidPermissions, setAllMidPermissions] = useState<MidPermission[]>(
        []
    )
    const [activePermissions, setActivePermissions] = useState<MidPermission[]>(
        []
    )
    const [midsUntouched, setMidsUntouched] = useState<boolean>(true)
    const [alertSnackbarOpen, setAlertSnackbarOpen] = useState<boolean>(false)
    const [
        alertSnackbarProps,
        setAlertSnackbarProps,
    ] = useState<alertSnackbarContentProps>({})

    useEffect(() => {
        if (!merchantId) return
        setIsLoadingMids(true)
        setAllMidPermissions([])
        UsersApi.getMids(merchantId, midSearchValue, { status_id: 2 })
            .then(setAllMidPermissions) // { status_id :2 } - Returns only active MIDs.
            .catch(() => {
                setAlertSnackbarProps({
                    message: `An error occurred while loading MIDs. Please try again later.`,
                    title: 'Error',
                    intent: 'error',
                })
                setAlertSnackbarOpen(true)
            })
            .finally(() => setIsLoadingMids(false))
    }, [midSearchValue, merchantId])

    useEffect(() => {
        UsersApi.getUserMids(userId).then(setActivePermissions)
    }, [userId])

    const sendSearchValue = useCallback(
        debounce((value) => {
            setMidSearchValue(value)
        }, 500),
        []
    )

    const clearActivePermissions = () => {
        setMidsUntouched(false)
        setActivePermissions([])
    }

    const submitNewMids = () => {
        UsersApi.setMidPermissions(userId, activePermissions)
            .then(() => {
                setAlertSnackbarMainProps({
                    title: 'Success',
                    message: 'Mid permissions were successfully updated.',
                    intent: 'success',
                })
                setAlertSnackbarMainOpen(true)
                setModalOpen(false)
            })
            .catch(() => {
                setAlertSnackbarMainProps({
                    title: 'Error',
                    message: 'A problem occurred setting mid permissions.',
                    intent: 'error',
                })
                setAlertSnackbarMainOpen(true)
                setModalOpen(false)
            })
    }

    return (
        <div className={`emp-midPermissions-permissionsWrapper`}>
            <Typography
                className={`emp-midPermissions-contentTextWrap`}
                variant="body1"
            >
                Please select the MIDs this user should be able to access, then
                click Save. No specified MID Permissions allows this user to
                access all available MIDs. To clear all the MID restrictions for
                this user, please click Clear MIDs.
            </Typography>
            <Autocomplete
                className={`${'emp-midPermissions-autocomplete'} ${className}`}
                autoComplete
                multiple
                limitTags={4}
                getOptionLabel={(option: MidPermission): string =>
                    option.name ?? ''
                }
                isOptionEqualToValue={(
                    option: MidPermission,
                    value: MidPermission
                ) => option.id === value.id}
                options={allMidPermissions}
                loading={isLoadingMids}
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                onChange={(_e, val) => {
                    setMidsUntouched(false)
                    setActivePermissions(val)
                }}
                value={activePermissions}
                filterOptions={(options: MidPermission[]) => options}
                inputValue={midSearchTextField}
                onInputChange={(_, value): void => {
                    setMidSearchTextField(value)
                    sendSearchValue(value)
                }}
                renderInput={(params: any): React.ReactNode => (
                    <TextField
                        {...params}
                        label={label}
                        variant={variant}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
            <div className={`emp-midPermissions-btnGrp`}>
                <Button
                    classes={{ root: 'emp-midPermissions-btn' }}
                    variant="contained"
                    color="secondary"
                    disabled={midsUntouched}
                    onClick={submitNewMids}
                >
                    Save
                </Button>

                <Button
                    classes={{ root: 'emp-midPermissions-btn' }}
                    onClick={clearActivePermissions}
                    variant="contained"
                    color="primary"
                    disabled={activePermissions.length === 0}
                >
                    Clear MIDs
                </Button>
            </div>
            <AlertSnackbar
                content={alertSnackbarProps}
                open={alertSnackbarOpen}
                onClose={() => setAlertSnackbarOpen(false)}
                showCloseIcon
            />
        </div>
    )
}

export default MidPermissions
